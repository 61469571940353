/* reset css */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i,dl, dt, dd, ol, ul, lifieldset, form, label, legend,table, caption, tbody, tfoot, thead, tr, th, td,article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary,time, mark, audio, video {margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent;}
body {line-height:1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section { display:block;}
ul {list-style-type:none;}
blockquote, q {quotes:none;}
blockquote:before, blockquote:after,q:before, q:after {content:'';content:none;}
a {margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;text-decoration: none;}
ins {background-color:#ff9;color:#000;text-decoration:none;}
mark {background-color:#ff9;color:#000; font-style:italic;font-weight:bold;}
del {text-decoration: line-through;}
abbr[title], dfn[title] {border-bottom:1px dotted;cursor:help;}
table {border-collapse:collapse;border-spacing:0;}
hr {display:block;height:1px;border:0;   border-top:1px solid #cccccc;margin:1em 0;padding:0;}
input, select {vertical-align:middle;}
input, button, textarea, select {font-size: 16px;margin: 0;padding: 0;background: none;border: none;border-radius: 0;outline: none;-webkit-appearance: none;-moz-appearance: none;appearance: none;width: 100%;}


/* common css */
* { -moz-box-sizing: border-box;-webkit-box-sizing: border-box;box-sizing: border-box; /* remember: box-sizing is not supported by IE7 :( */ outline: none;vertical-align: top;margin: 0;padding: 0;}
*, *:after, *:before {-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
html{ width: 100%; z-index: 1;}
html,body{
    font-family: 'Noto Sans JP', sans-serif;
    line-height: 1.6;
    letter-spacing: .2vw;
    margin: 0; 
    padding: 0; 
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
img{width: 100%;}
blockquote,body,dd,div,dl,dt,fieldset,form,h1,h2,h3,h4,h5,h6,input,li,ol,p,pre,td,textarea,th,ul{margin:0;padding:0;}
p{margin: 0;padding: 0;}
a{color: inherit;outline: none;margin: 0;padding: 0;text-decoration: none;}
div,figure,a,img,input[type="submiit"],.fa,a:after,a:before{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
p,h1,h2,h3,h4,h5{display: block;position: relative;;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;margin: 0;padding: 0;}
input, button, textarea, select {font-size: 1vw;margin: 0;padding: 0;background: none;border: none;border-radius: 0;outline: none;-webkit-appearance: none;-moz-appearance: none;appearance: none;width: 100%;}
input[type="radio"]{-webkit-appearance: radio;-moz-appearance: radio;appearance: radio;display: inline-block;}
.clearfix:before,.clearfix:after {content: " ";display: table;}
.clearfix:after {clear: both;}
.clearfix {*zoom: 1;}
.pc{display: block!important;}
.sp{display: none!important;}
.inline-pc{display: inline-block!important;}
.inline-sp{display: none!important;}
.pc-tb{display: block;}
.tb-sp{display: none;}
.invisible{opacity: 0;}
.visible{opacity: 1;}
.hide{display: none;}
.inline{display: inline-block;}
.block{display: block;}

@media only screen and (max-width: 768px) {

.pc{display: none!important;}
.sp{display: block!important;}
.inline-pc{display: none!important;}
.inline-sp{display: inline-block!important;}
.pc-tb{display: none;}
.tb-sp{display: block;}
html,body{ 
    font-size: 3vw;
}

}

.wrapper{
    overflow: hidden;
}
body{
    min-width: 1px;
}
img{
    width: 100%;
}
main{
    display: block;
    height: 100%;
    width: 100%;
    article{
        width: 100%;
        height: 100%;
    }
}
section{
    position: relative;
}