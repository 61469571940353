.admin {
  &__create_btn {
    width: 150px;
    height: 80px;
    background-color: green;
    font-size: 20px;
    padding: 8px 16px;
    border-radius: 8px;
    color: #fff;
  }
  &__recipe_img {
    width: 200px;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
    }
  }
  &__long_content {
    overflow: hidden;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__recipe_input {
    input {
      font-size: 18px!important;
      border-radius: 8px;
      width: 100%;
    }
  }
}

.admin-table {
  width: 1100px;
}

.admin-th, .admin-td {
  width: 150px;
  vertical-align: middle;
}

.fixed01{
  position: sticky;
  left: 0;
  background: #fff;
  &:before{
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }
}

.data-contents {
  padding: 0;
}

@media only screen and (min-width: 769px) {
  input,
  textarea,
  table,
  ul {
    font-size: 16px !important;
  }
  body {
    font-size: 100% !important;
  }
  .l-inner {
    width: 100%;
  }
  .page-data {
    padding: 0;
    font-size: 16px;
  }
}