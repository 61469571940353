.inline{
	display: inline-block;
}
.flex{
	display: flex;
}
.f-start{
	justify-content: flex-start;
}
.f-end{
	justify-content: flex-end;
}
.f-center{
	justify-content: center;
}
.f-space{
	justify-content: space-between;
}
.a-center{
	align-items: center;
}
.a-end{
	align-items: end;
}
.f-wrap{
	flex-wrap: wrap;
}
.f-l-wrap{
	flex-wrap: wrap-reverse;
}

@media only screen and (max-width: 768px) {

.f-center-sp{
	justify-content: center;
}

}


/*
-------------------------------------------
Color
-------------------------------------------
*/

$white:#FFFFFF;
$gray:#F4F8F8;

$blue100:#29D6CB;

$blk100:rgba(15, 33, 44, 1);
$blk80:rgba(15, 33, 44, 0.80);
$blk60:rgba(15, 33, 44, 0.60);
$blk40:rgba(15, 33, 44, 0.40);
$blk20:rgba(15, 33, 44, 0.20);
$blk10:rgba(15, 33, 44, 0.10);
$blk5:rgba(15, 33, 44, 0.05);
$blk3:rgba(15, 33, 44, 0.03);

$red100:rgba(224, 91, 91, 1);
$red40:rgba(224, 91, 91, 0.40);
$red10:rgba(224, 91, 91, 0.10);

$green100:rgba(91, 221, 50, 1);

$gray:#F4F7FB;
$b-gray:#f2f7f9;
$d-gray:#7E8B92;

.gradient{
	background: linear-gradient(90deg, #23D8CD 0%, #21C4DA 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.bg-gradient{
	background: linear-gradient(90deg, rgba(35, 216, 205, 1) 0%, rgba(33, 196, 218, 1) 100%);
}
.bg-gradient-l{
	background: linear-gradient(90deg, rgba(35, 216, 205, 0.10) 0%, rgba(33, 196, 218, 0.10) 100%);
}
.white{
	color: $white;
}

/*
-------------------------------------------
Font 
-------------------------------------------
*/

html,body{
    font-family: 'Outfit','Noto Sans JP', sans-serif;
    line-height: 1.7;
    letter-spacing: 0px;
    background-color: $white;
    color: $blk100;
    font-weight: 400;
}
h1,h2,h3,h4,h5{
	font-weight: 700;
}

@media only screen and (max-width: 768px) {

html,body{
    font-size: 3.3vw;
}

}

/*
-------------------------------------------
Inner
-------------------------------------------
*/

.xl-inner{
	width: 92vw;
	margin: 0 auto;
	position: relative;
}
.l-inner{
	width: 84vw;
	margin: 0 auto;
	position: relative;
}
.m-inner{
	width: 80vw;
	margin: 0 auto;
	position: relative;
}
.s-inner{
	width: 76vw;
	margin: 0 auto;
	position: relative;
}
.xs-inner{
	width: 54vw;
	margin: 0 auto;
	position: relative;
}

@media only screen and (max-width: 768px) {

.xl-inner{
	width: 92vw;
}
.l-inner{
	width: 92vw;
}
.m-inner{
	width: 84vw;
}
.s-inner{
	width: 80vw;
}

}

/*
-------------------------------------------
Image
-------------------------------------------
*/

figure{
	font-size: 0;
}
.bg{
	background-size: cover;
	background-position: center;
}
.bg-cover{
	&:before{
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, #0F212C 0%, rgba(15, 33, 44, 0.30) 100%);
		bottom: 0;
		left: 0;
	}
}

@media only screen and (max-width: 768px) {



}

/*
-------------------------------------------
Appearance
-------------------------------------------
*/

/* border */
.border{
	border-radius: 2.4vw;
}
.border-s{
	border-radius: 1.6vw;
}
.circle{
	border-radius: 1000px;
}
.border-gradient{
	border: 1px solid #FF6BBB;
	border-image: linear-gradient(130deg, #FF6BBB 20%, #7544FF 100%);
  	border-image-slice: 1;
}

@media only screen and (max-width: 768px) {

/* border */


}

/* shadow */
.shadow{
	box-shadow: 0px 1.2vw 3.6vw 0px $blk10;
}
.shadow-s{
	box-shadow: 0px .8vw 1.6vw 0px $blk5;
}
.shadow-blue{
	box-shadow: 0px .6vw 1.2vw 0px rgba(34, 206, 212, 0.15);
}

/* align */
.t-center{
	text-align: center;
}

@media only screen and (max-width: 768px) {

.t-center-sp{
	text-align: center!important;
}

}


/* opacity */
.opa-l{
	opacity: 0.25;
}
.opa{
	opacity: 0.5;
}
.opa-s{
	opacity: 0.75;
}

/*
-------------------------------------------
Compornents
-------------------------------------------
*/

/* btn */


@media only screen and (max-width: 768px) {



}


@media only screen and (max-width: 768px) {



}


@media only screen and (max-width: 768px) {

}

/*
-------------------------------------------
Margin & Padding
-------------------------------------------
*/

.sec-pt{
	padding-top: 6vw;
}
.sec-pt-s{
	padding-top: 3.6vw;
}
.sec-pb{
	padding-bottom: 6vw;
}
.sec-pb-s{
	padding-bottom: 4.4vw;
}

.mb-xxl{
	margin-bottom: 4.8vw;
}
.mb-xl{
	margin-bottom: 3.6vw;
}
.mb-l{
	margin-bottom: 2.8vw;
}
.mb{
	margin-bottom: 2vw;
}
.mb-s{
	margin-bottom: 1.2vw;
}
.mb-xs{
	margin-bottom: .8vw;
}
.mb-xxs{
	margin-bottom: .4vw;
}

@media only screen and (max-width: 768px) {

.sec-pt{
	padding-top: 16vw;
}
.sec-pt-s{
	padding-top: 9.2vw;
}
.sec-pb{
	padding-bottom: 16vw;
}
.sec-pb-s{
	padding-bottom: 10vw;
}

.mb-xxl{
	margin-bottom: 9.6vw;
}
.mb-xl{
	margin-bottom: 7.2vw;
}
.mb-l{
	margin-bottom: 7.2vw;
}
.mb{
	margin-bottom: 5.4vw;
}
.mb-s{
	margin-bottom: 3.2vw;
}
.mb-xs{
	margin-bottom: 1.8vw;
}
.mb-xxs{
	margin-bottom: 1.2vw;
}

}

