@charset "utf-8";

@import 'base';
@import 'common';

/* body */
body {
  background-color: $white;
}

select::-ms-expand {
  display: none;
  appearance: none;
}

@media only screen and (min-width: 769px) {

  body {
    background-color: $blk5;
  }

  .wrapper {
    background-color: $white;
    transform: scale(0.3);
    transform-origin: top;
  }

}

/*
-------------------------------------------
Compornent
-------------------------------------------
*/

/* header */
header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid $blk10;
  z-index: 1000;
  background-color: $white;

  .l-inner {
    height: 14vw;
  }

  .logo {
    width: 22vw;
  }
}

/* foot-common-menu */
.foot-common-menu {
  background-color: $white;
  height: 16vw;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  border-top: 1px solid $blk10;

  ul {
    justify-content: space-around;

    li {
      text-align: center;
      padding-top: 3.2vw;

      a {
        display: block;
      }

      figure {
        display: block;
        height: 6vw;
        margin-bottom: 0.6vw;

        img {
          margin: auto;
          width: auto;
          height: 100%;
        }
      }

      .active-icon {
        display: none;
      }

      span {
        font-size: 3vw;
        color: $d-gray;
        font-weight: 500;
      }
    }

    li.active {
      .active-icon {
        display: block;
      }

      .inactive-icon {
        display: none;
      }

      span {
        background: linear-gradient(90deg, #23D8CD 0%, #21C4DA 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

/* footer */
footer {
  padding: 6vw 0 6vw;
  position: relative;
  text-align: center;
  background-color: $b-gray;

  .copyright {
    font-size: 2.8vw;
    color: $blk40;
  }
}

.footer-sign {
  background-color: $white;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}

/* page-heading */
.page-sign {
  padding-top: 34vw;
  height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
}

.page-heading {
  margin-bottom: 16vw;

  .page-title {
    text-align: center;

    span {
      font-size: 3vw;
      font-weight: bold;
      margin-bottom: 3vw;
      line-height: 1;
      display: inline-block;
    }

    h1 {
      font-size: 6vw;
      line-height: 1;
    }

    p {
      margin-top: 5.4vw;
      font-size: 3.2vw;
    }
  }
}

.page-todo {
  padding-top: 14vw;

  .page-heading {
    padding-top: 4.8vw;
  }
}

.page-data {
  min-height: 100vh;
  padding: 14vw 0 16vw 0;
}

.page-account-setting {
  padding-top: 14vw;

  .page-heading {
    padding-top: 4.8vw;
  }
}

@media only screen and (min-width: 769px) {

  .page-sign {
    padding-top: 34vw;
    height: calc(var(--vh, 1vh) * 333);
    height: 333vh;
  }

}


/*
-------------------------------------------
form compornent
-------------------------------------------
*/

/* sign-up , sign-in */
.page-sign {
  fieldset {
    margin-bottom: 6vw;

    .caption {
      font-size: 3vw;
      color: $blk60;
      margin-top: 2.4vw;
      line-height: 1.4;
    }

    .caption100 {
      color: $blk100;
    }

    .er-caption {
      color: $red100;
    }
  }

  fieldset.submit {
    margin-top: 12vw;
    margin-bottom: 0;
    text-align: center;

    input[type="submit"],
    button[type="submit"] {
      display: block;
      width: 48vw;
      padding: 4.4vw 0 4.4vw;
      background: linear-gradient(90deg, rgba(35, 216, 205, 0.10) 0%, rgba(33, 196, 218, 0.10) 100%);
      color: $blue100;
      font-size: 3.4vw;
      font-weight: 600;
      border: none;
      margin: 0 auto;
    }

    input[type="submit"]:disabled,
    button[type="submit"]:disabled {
      background: $blk10;
      color: $blk40;
    }

    a {
      display: inline-block;
      text-decoration: underline;
      font-size: 3vw;
      margin-top: 3.6vw;
      color: $blk80;

      &:hover {
        color: $blue100;
      }
    }
  }

  label {
    font-size: 3.4vw;
    margin-bottom: 2.8vw;
    line-height: 1;
    font-weight: 500;
    display: block;
  }

  input,
  textarea {
    background-color: $blk3;
    border: 1px solid $blk5;
    border-radius: 1.2vw;
    font-size: 16px;
    font-weight: 500;
    padding: 3.6vw 4vw 3.4vw;
    transition: all .4s;
  }

  input::placeholde,
  textarea::placeholder {
    color: $blk60;
  }

  input:focus,
  textarea:focus {
    border: solid 1px $blue100;
  }

  textarea {
    height: 36vw;
  }

  input.readonly {
    background-color: $white;
    border: 1px solid $blk10;
  }

  input.er {
    border: 1px solid $red100;
  }

  .notice-box {
    padding: 5vw 5vw 3vw;
    border: 1px solid $red40;
    background-color: $red10;
    border-radius: 1.2vw;
    margin-top: 16vw;

    p {
      color: $red100;
      line-height: 1.5;
      margin-bottom: 2.8vw;

      span {
        font-weight: 600;
      }
    }
  }
}


@media only screen and (min-width: 769px) {

  input,
  textarea {
    font-size: 3.3vw !important;
  }

}


/*
-------------------------------------------
todo compornent
-------------------------------------------
*/
.checking {
  border-radius: 100vw;
  background-color: $blk40;
  width: 6.4vw;
  height: 6.4vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  figure {
    width: 3.2vw;
    opacity: 0.5;
  }
}

.todo-completed {
  .checking {
    background-color: $green100;

    figure {
      opacity: 1;
    }
  }
}

.date-box {
  overflow: hidden;

  .title {
    background-color: $blk100;
    text-align: center;
    position: relative;
    padding: 4.8vw 0;

    h2 {
      line-height: 1;
      color: $white;
      font-size: 4vw;
    }

    figure {
      width: 6vw;
      height: 6vw;
      position: absolute;
      right: 4vw;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .title.active {
    figure {
      transform: rotate(180deg);
    }
  }

  .calendar-box {
    height: 0;
    overflow: hidden;
    padding: 0 6vw;
    background-color: $blk5;

    .content {
      opacity: 0;
    }
  }

  .calendar-box.active {
    height: 100%;
    padding: 6vw 6vw;

    .content {
      opacity: 1;
      height: 450px;
    }
  }
}

.theme-box {
  padding: 3.2vw 0 5.6vw;

  .content {
    padding: 4vw;
    background-color: $b-gray;

    .title {
      gap: 1.2vw;
      margin-bottom: 2.4vw;

      figure {
        width: 4.4vw;
        position: relative;
        top: -0.4vw;
      }

      h4 {
        font-size: 3.3vw;
        line-height: 1;
      }
    }

    p {
      line-height: 1.5;
      font-size: 3.5vw;
      font-weight: 500;
    }
  }
}

.todo-contents {
  background-color: $b-gray;
  padding: 4.8vw 0;
  margin-bottom: 40px;

  li {
    background-color: $white;
    width: 100%;
    margin-bottom: 4vw;
    overflow: hidden;

    .checking {
      position: absolute;
      top: 3.2vw;
      right: 3.2vw;
    }

    .kv {
      height: 48vw;
      position: relative;

      .title {
        width: 100%;
        padding: 4.8vw 4.8vw 5.2vw;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $white;
        gap: 3.6vw;
        align-items: end;

        .main {
          width: 71.2vw;

          h3 {
            font-size: 3.8vw;
            line-height: 1.5;
          }
        }

        .sub-info {
          gap: 2vw;
          color: $blk100;
          opacity: 0.8;
          position: relative;
          margin-bottom: 1.6vw;

          p {
            font-size: 3.2vw;
            line-height: 1;
          }

          span {
            width: 1px;
            height: 100%;
            height: 3.2vw;
            background-color: $blk100;
          }
        }

        figure {
          width: 9.2vw;
          position: relative;
          top: -0.8vw;
        }
      }
    }

    .kv.bg-cover {
      .title {
        background-color: rgba(0, 0, 0, 0);

        .sub-info {
          color: $white;
          margin-bottom: 2.4vw;

          span {
            background-color: $white;
          }
        }

        .main {
          h3 {
            color: $white;
          }
        }
      }
    }
  }

  li.col2 {
    width: 44vw;

    .kv {
      height: 32vw;
      position: relative;
    }

    .title {
      padding: 4vw 3.2vw 4vw 4vw;
      gap: 1.2vw;

      h3 {
        font-size: 3.3vw;
      }

      figure {
        width: 7.2vw;
      }
    }
  }
}

.todo-completed {
  .checking {
    background-color: $green100;

    figure {
      opacity: 1;
    }
  }
}

.date-box {
  overflow: hidden;

  .title {
    background-color: $blk100;
    text-align: center;
    position: relative;
    padding: 4.8vw 0;

    h2 {
      line-height: 1;
      color: $white;
      font-size: 4vw;
    }

    figure {
      width: 6vw;
      height: 6vw;
      position: absolute;
      right: 4vw;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .title.active {
    figure {
      transform: rotate(180deg);
    }
  }

  .calendar-box {
    height: 0;
    overflow: hidden;
    padding: 0 6vw;
    background-color: $blk5;

    .content {
      opacity: 0;
    }
  }

  .calendar-box.active {
    height: 100%;
    padding: 6vw 6vw;

    .content {
      opacity: 1;
      height: 450px;
    }
  }
}

.theme-box {
  padding: 3.2vw 0 5.6vw;

  .content {
    padding: 4vw;
    background-color: $b-gray;

    .title {
      gap: 1.2vw;
      margin-bottom: 2.4vw;

      figure {
        width: 4.4vw;
        position: relative;
        top: -0.4vw;
      }

      h4 {
        font-size: 3.3vw;
        line-height: 1;
      }
    }

    p {
      line-height: 1.5;
      font-size: 3.5vw;
      font-weight: 500;
    }
  }
}

.todo-contents {
  background-color: $b-gray;
  padding: 4.8vw 0;

  li {
    background-color: $white;
    width: 100%;
    margin-bottom: 4vw;
    overflow: hidden;

    .checking {
      position: absolute;
      top: 3.2vw;
      right: 3.2vw;
    }

    .kv {
      height: 48vw;
      position: relative;

      .title {
        width: 100%;
        padding: 4.8vw 4.8vw 5.2vw;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $white;
        gap: 3.6vw;
        align-items: end;

        .main {
          width: 71.2vw;

          h3 {
            font-size: 3.8vw;
            line-height: 1.5;
          }
        }

        .sub-info {
          gap: 2vw;
          color: $blk100;
          opacity: 0.8;
          position: relative;
          margin-bottom: 1.6vw;

          p {
            font-size: 3.2vw;
            line-height: 1;
          }

          span {
            width: 1px;
            height: 100%;
            height: 3.2vw;
            background-color: $blk100;
          }
        }

        figure {
          width: 9.2vw;
          position: relative;
          top: -0.8vw;
        }
      }
    }

    .kv.bg-cover {
      .title {
        background-color: rgba(0, 0, 0, 0);

        .sub-info {
          color: $white;
          margin-bottom: 2.4vw;

          span {
            background-color: $white;
          }
        }

        .main {
          h3 {
            color: $white;
          }
        }
      }
    }
  }

  li.col2 {
    width: 44vw;

    .kv {
      height: 32vw;
      position: relative;
    }

    .title {
      padding: 4vw 3.2vw 4vw 4vw;
      gap: 1.2vw;

      h3 {
        font-size: 3.3vw;
      }

      figure {
        width: 7.2vw;
      }
    }
  }
}

.page-todo {
  .page-heading {
    margin-bottom: 0;

    .heading {
      padding: 5.2vw 0 0vw;
      border-bottom: 0px dotted $blk20;

      h3 {
        font-size: 3.3vw;
        font-weight: 500;
        color: $blk60;
        line-height: 1;
      }

      .date-count {
        gap: 1.2vw;

        figure {
          width: 4vw;
          position: relative;
          top: -0.6vw;
        }

        p {
          font-weight: 600;
          font-size: 3.3vw;
          line-height: 1;
        }
      }
    }
  }
}

/* todo-level01 */
.page-todo-level01 {
  .page-heading {
    height: 60vw;
    position: relative;
    margin-bottom: 0;

    .head-navi {
      position: absolute;
      left: 4vw;
      top: 4vw;
      z-index: 10;

      .btn-back {
        figure {
          width: 8vw;
        }
      }
    }

    .m-inner {
      position: absolute;
      left: 8vw;
      bottom: 12.8vw;
      z-index: 10;

      .cat {
        opacity: .7;
        line-height: 1;
        margin-bottom: 2.8vw;
        color: $white;
      }

      h2 {
        font-size: 4vw;
        line-height: 1.4;
        color: $white;
      }
    }

    .bg {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .overview {
    background-color: $white;
    position: relative;
    top: -8vw;
    z-index: 10;

    .m-inner {
      width: 82.4vw;
    }
  }

  .contents {
    padding-bottom: 6vw;
    background-color: $b-gray;
    min-height: calc(100vh - 76vw);
  }
}

.page-workout {
  .overview {
    ul {
      padding: 4.8vw 0;
      gap: 2.8vw;

      li {
        background-color: $b-gray;
        height: 12vw;
        gap: 2vw;
        width: 50%;
        padding: 0 3.2vw;

        figure {
          width: 4.8vw;
        }

        h3 {
          font-size: 3.4vw;
          line-height: 1;
        }
      }
    }

    .text {
      padding: 4.8vw 0;
      border-top: 1px dotted $blk10;

      p {
        font-size: 3.2vw;
      }
    }
  }
}

.page-todo-input {
  .overview {
    .text {
      padding: 4.8vw 0;
      border-bottom: 1px dotted $blk10;

      p {
        font-size: 3.2vw;
      }
    }

    .input-content {
      padding: 4.8vw 0 6vw;

      fieldset {
        margin-bottom: 4vw;

        label {
          font-size: 3.2vw;
          font-weight: 500;
          display: block;
          margin-bottom: 2vw;

          span {
            color: $red100;
          }
        }

        ul {
          gap: 1.6vw;

          li {
            width: 50%;
          }
        }

        li.unit {
          width: auto;
          font-size: 3.4vw;
          opacity: .5;
          line-height: 1;
          font-weight: 500;

          span {
            font-size: 6vw;
            font-weight: 600;
          }
        }

        select,
        .select-input {
          height: 12vw;
          background-color: $b-gray;
          font-size: 16px;
          padding: 0 3.2vw;
          font-weight: 600;
          font-family: 'Outfit', 'Noto Sans JP', sans-serif;
        }

        .file-upload {
          background-color: $b-gray;
          font-size: 3.2vw;
          color: $blue100;
          padding: 3.2vw;
          font-weight: 600;
          text-align: center;
          font-family: 'Outfit', 'Noto Sans JP', sans-serif;
        }

        input[type="file"] {
          display: none;
        }

        .select-box {
          position: relative;

          &:after {
            content: "";
            width: 6vw;
            height: 6vw;
            display: block;
            background-image: url(../images/arrow.svg);
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            right: 3.2vw;
            bottom: 0;
            margin: auto;
            transform: rotate(90deg);
          }
        }

        .submit-box {
          margin-top: 2.8vw;
          position: relative;

          input[type="submit"],
          button[type="submit"] {
            color: $white;
            font-size: 4vw;
            font-weight: 600;
            padding: 4vw 0;
          }

          &:after {
            content: "";
            width: 6vw;
            height: 6vw;
            display: block;
            background-image: url(../images/arrow-back.svg);
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            right: 3.2vw;
            bottom: 0;
            margin: auto;
            transform: rotate(180deg);
          }
        }
      }

      fieldset:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }
}

.video-list {
  li {
    box-shadow: 0px .8vw 1.6vw 0px $blk5;
    overflow: hidden;
    height: 24vw;
    margin-bottom: 3.2vw;
    background-color: $white;

    a {
      width: 100%;
      height: 100%;

      .kv {
        width: 30%;
        height: 100%;
      }

      .title {
        width: 70%;
        padding: 3.2vw 11.2vw 3.2vw 3.2vw;
        position: relative;

        h3 {
          font-size: 3.4vw;
          margin-bottom: 1.6vw;
          line-height: 1.4;
        }

        p {
          opacity: .7;
        }

        figure {
          position: absolute;
          width: 8vw;
          right: 3.2vw;
          bottom: 3.2vw;
        }
      }
    }
  }

  li.inactive {
    opacity: .3;
    box-shadow: none;
  }
}

.page-todo-level02 {
  margin-bottom: 64px;

  .page-heading {
    margin-bottom: 7.2vw;

    .head-navi {
      position: absolute;
      top: 4vw;
      left: 4vw;
      z-index: 2;
    }

    .page-title {
      padding: 4.8vw 0 7.2vw;
      background-color: $white;
      border-bottom: 1px solid $blk5;

      .l-inner {
        padding: 0 2vw;
      }

      h2 {
        font-size: 4vw;
        line-height: 1.4;
        margin-bottom: 2.8vw;
        text-align: left;
      }

      .sub-info {
        gap: 2vw;
        color: $blk100;
        opacity: 0.8;
        position: relative;
        margin-bottom: 4.8vw;

        p {
          font-size: 3.2vw;
          line-height: 1;
          margin-top: 0vw;
        }

        span {
          width: 1px;
          height: 100%;
          height: 3.2vw;
          background-color: $blk20;
          margin-bottom: 0vw;
        }
      }
    }
  }

  .contents {
    padding-bottom: 7.2vw;

    .cl-content {
      padding: 0 2vw 7.2vw;
      margin-bottom: 7.2vw;
      border-bottom: 1px solid $blk5;

      h2 {
        font-size: 3.6vw;
        margin-bottom: 3.6vw;
      }

      .dot-list {
        list-style-type: disc;
        color: $blue100;
        padding-left: 4vw;
      }

      .num-list {
        list-style-type: decimal;
        color: $blue100;
        padding-left: 3.2vw;
      }

      li {
        margin-bottom: 2.8vw;

        p {
          font-size: 3.2vw;
          color: $blk100;
        }
      }

      li:last-child {
        margin-bottom: 0vw;
      }
    }

    .cl-content:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
}

.account-setting-contents {
  min-height: calc(100vh - 14vw);
  background-color: #E8ECEF;
  padding: 4vw;

  .l-inner {
    display: grid;
    gap: 4vw;

    .title {
      font-weight: 700;
      font-size: 3.8vw;
    }

    ul {
      padding: 0 4vw;
      background-color: $white;
      border-radius: 2vw;

      li {
        padding: 4vw 0;
        border-bottom: 1px solid $blk10;

        .item-label {
          font-weight: 700;
        }
      }

      li:last-child {
        border-bottom: none;
      }
    }

    .btn-area {
      display: grid;
      gap: 4vw;

      .btn {
        height: 5vh;
        background-color: #F3F5F7;
        border-radius: 2vw;
        font-weight: 700;
        font-size: 13px;
      }
    }
  }
}

.data-contents {
  min-height: calc(100vh - 16vh);
  background-color: $b-gray;
  padding: 4vw;

  ul {
    display: grid;
    gap: 3vh;

    li {
      background-color: $white;
      width: 100%;

      overflow: hidden;

      .data-container {
        border-bottom: 1px solid $blk5;
        padding: 4vw;

        .title-box {
          gap: 2vw;
          margin-bottom: 2vh;
        }

        .title {
          font-weight: 700;
        }

        .data-card-container {
          display: grid;
          gap: 4vw;

          .data-card {
            background-color: $b-gray;
            padding: 4vw;
            border-radius: 1.2vw;
            gap: 6vw;

            .data-card-left {
              .time {
                font-weight: 700;
                font-size: 6vw;
              }

              .time-label {
                font-size: 4vw;
              }

              .time-title {
                font-weight: 700;

              }
            }

            .data-card-right {
              .time {
                font-weight: 700;
                font-size: 5vw;
              }

              .time-label {
                font-size: 4vw;
              }

              .time-title {
                font-weight: 700;
                color: $d-gray;

              }
            }
          }

        }


        .data-box {
          gap: 4vw;

          .value {
            font-size: 7vw;
            font-weight: 700;
          }

          .value-kg {
            font-size: 3.6vw;
            font-weight: 700;
          }

          .date {
            font-size: 3vw;
            color: $d-gray;
            font-weight: 600;
          }

          .week-value {
            font-size: 5vw;
            color: $blk40;
            font-weight: 700;
            color: $d-gray;
          }

          .week-and-month-value-kg {
            font-size: 3.6vw;
          }

          .week-and-month-title {
            color: $d-gray;
            font-weight: 600;
          }

          .month-value {
            font-size: 5vw;
            color: $d-gray;
            font-weight: 700;
          }

          .plus {
            color: #E05B5B;
          }

          .minus {
            color: #5BD332;
          }
        }

        .data-graph {
          width: 80%;
        }

      }

      .graph-container {
        padding: 4vw;

        .title {
          font-weight: 700;
          margin-bottom: 3vw;
        }

        .data-box {
          gap: 3vw;
          margin-bottom: 3vw;

          .button {
            padding: 1vw 4vw;
            border: 1px solid $blk10;
            background-color: white;
            color: black;
            border-radius: 100px;
            font-weight: 700;
            cursor: pointer;
          }

          .active {
            background-color: black;
            color: white;
          }

        }

        .graph {
          background-color: $b-gray;
          height: 30vw;
        }
      }
    }
  }
}

.tags {
  gap: 2vw;

  li {
    background-color: $b-gray;
    padding: 2.4vw 3.6vw;
    gap: 2.4vw;

    figure {
      width: 2.8vw;
    }

    p {
      font-weight: 500;
      font-size: 3.2vw;
      margin-top: 0vw !important;
      line-height: 1 !important;
    }
  }
}

.pull-content {
  li {
    background-color: $b-gray;
    margin-bottom: 6vw;

    .title {
      padding: 4vw 4.8vw;
      position: relative;

      .title-head {
        gap: 1.6vw;

        figure {
          width: 4.8vw;
        }

        h3 {
          font-size: 3.6vw;
        }
      }

      .btn {
        width: 6vw;
        height: 6vw;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 4vw;
        margin: auto;
        transform: rotate(90deg);
      }
    }

    .text {
      height: 0;
      padding: 0 4.8vw;
      overflow: hidden;

      p {
        display: none;
      }
    }

    .text.active {
      height: 100%;
      padding: 0 4.8vw 4.8vw;
      overflow: auto;

      p {
        display: block;
      }
    }
  }

  li:last-child {
    margin-bottom: 0;
  }
}

.foot-next-btn {
  display: block;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: $blk100;
  color: $white;
  text-align: center;
  padding: 4vw 0;
  font-size: 3.4vw;
  font-weight: 600;

  figure {
    width: 8vw;
    height: 8vw;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 4vw;
    margin: auto;
  }

  &.link-disabled {
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.5;
      z-index: 11;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.fc-event {
  pointer-events: none;
}

@media only screen and (max-width: 768px) {}